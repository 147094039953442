a {
    color: rgb(0, 79, 151);
}

.alert {
    background-color: rgb(239, 125, 0) !important;   
    color: white !important;
    font-size: 16px;
    font-weight: bold;  
}

.alert:hover {
    background-color: rgb(255,163,62) !important;
    /* color: rgb(52, 100, 178) !important; */
}

[aria-label="FormDown"], [aria-label="FormUp"] {
    filter: brightness(0%);
}

body {
    background: whitesmoke;
}

.button {
    background-color: rgb(0, 79, 151) !important;    
    color: white !important;
    font-size: 16px;
    font-weight: bold;
}

.button:disabled {
    background-color: #dddddd !important;
    color: black !important;
}

.button:hover {
    background-color: rgb(52, 100, 178) !important;
}

#container {
    max-width: 99%;
    margin: 0px auto;
}

circle {
    fill: rgb(0, 79, 151);
}

h1, h2, h3, h4 {
    color: rgb(0, 79, 151);
}

header {
/* 
    border-top: 2px solid rgb(0, 79, 151); 
    border-left: 2px solid rgb(0, 79, 151); 
    border-right: 2px solid rgb(0, 79, 151); 
 */
    border-bottom: 2px solid rgb(0, 79, 151); 
    padding: 1em;
}

hr {
    height: 1px;
    color: rgb(0, 79, 151);
    background-color: rgb(0, 79, 151);
    border: none;
}

.mainBox {
    border-bottom: 2px solid rgb(0, 79, 151);
    background-color: rgb(240,240,240);
    padding: 1em;
    text-align: center;   
}

.text {
    color: rgb(0, 79, 151);
}

::-webkit-input-placeholder {
   text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
   text-align: center;  
}

::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;  
}

:-ms-input-placeholder {  
   text-align: center; 
}

@media (min-width: 0px) {
    #container {
        width: 100%;
    }
}

@media (min-width: 500px) {
    #container {
        width: 80%;
    }
}

@media (min-width: 800px) {
    #container {
        width: 60%;
    }
}

@media (min-width: 1200px) {
    #container {
         width: 40%;
    }
}
