/* https://docs.amplify.aws/ui/customization/theming/q/framework/react */
:root{
  --amplify-font-family: "Helvetica Neue",sans-serif;
  --amplify-primary-color: #004f97;
  --amplify-primary-tint: #3464B2; 
  --amplify-primary-shade: #004f97;
  --amplify-secondary-color: #666666;
  --amplify-secondary-tint: #666666; 
  --amplify-secondary-shade: #666666;
}

#amplify_authenticator {
    --container-height: auto;
}

/* #country-dial-code-select */
option[value="+1"] {
    display: none;
}
